import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const EmailForm = () => {
  const [state, setState] = useState({
    email: "",
    result: null,
    msg: "",
  })

  const handleInputEmailChange = e => {
    setState({
      ...state,
      email: e.target.value,
      result: null,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const data = await addToMailchimp(state.email)
    if (data.result === "success") {
      setState({
        ...state,
        email: "",
        result: data.result,
        msg: data.msg,
      })
    } else {
      setState({
        ...state,
        result: data.result,
        msg: data.msg,
      })
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="relative flex flex-wrap items-stretch w-full pt-1">
          <input
            type="email"
            className="relative flex-auto flex-grow flex-shrink w-px h-10 px-3 leading-normal border rounded-sm rounded-r-none border-opacity-40 border-body focus:border-primary focus:ring-0 focus:outline-none"
            placeholder="Your Email"
            required
            name="email"
            value={state.email}
            onChange={handleInputEmailChange}
          />
          <div className="flex -mr-px">
            <button
              className="flex items-center px-3 text-xs leading-relaxed tracking-[1px] text-white uppercase whitespace-no-wrap border border-l-0 rounded-sm rounded-l-none bg-primary   focus:outline-none"
              type="submit"
            >
              Subscribe
            </button>
          </div>
        </div>
      </form>
      {state.result && (
        <div
          className={`text-sm pt-4 ${
            state.result === "success" ? "text-green-600" : "text-red-600"
          }`}
        >
          {state.msg}
        </div>
      )}
    </>
  )
}

export default EmailForm
