import React from "react"

import * as styles from "./Icons.module.css"

const Compass = () => (
  <svg
    id="nc_icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className={styles.icon}
    data-type="outline"
  >
    <g transform="translate(0, 0)" fill="none">
      <line
        data-cap="butt"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeMiterlimit="10"
        x1="12"
        y1="1"
        x2="12"
        y2="4"
        strokeLinejoin="miter"
        strokeLinecap="butt"
      ></line>{" "}
      <line
        data-cap="butt"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeMiterlimit="10"
        x1="23"
        y1="12"
        x2="20"
        y2="12"
        strokeLinejoin="miter"
        strokeLinecap="butt"
      ></line>{" "}
      <line
        data-cap="butt"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeMiterlimit="10"
        x1="12"
        y1="23"
        x2="12"
        y2="20"
        strokeLinejoin="miter"
        strokeLinecap="butt"
      ></line>{" "}
      <line
        data-cap="butt"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeMiterlimit="10"
        x1="1"
        y1="12"
        x2="4"
        y2="12"
        strokeLinejoin="miter"
        strokeLinecap="butt"
      ></line>{" "}
      <circle
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        cx="12"
        cy="12"
        r="11"
        strokeLinejoin="miter"
      ></circle>{" "}
      <polygon
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        points=" 17,7 14,14 7,17 10,10 "
        strokeLinejoin="miter"
      ></polygon>
    </g>
  </svg>
)

export default Compass
