/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"

import GlobalContext from "../../contexts/GlobalContext"
import TopBar from "../TopBar/TopBar"
import Header from "../Header/Header"
import Sidebar from "../Sidebar/Sidebar"
import ScrollToTop from "../ScrollToTop/ScrollToTop"
import Footer from "../Footer/Footer"

const Layout = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    setShowLoader(false)
  }, [])

  const gContext = useContext(GlobalContext)

  return (
    <>
      <div
        className={`text-4xl bg-white flex items-center justify-center fixed w-full h-full transition-all duration-[1500ms] ease-in inset-0 ${
          showLoader ? "opacity-100 z-50" : "opacity-0 z-[-1]"
        }`}
      >
        Loading
      </div>

      <div>
        <Sidebar />
        {/* Overlay after toggle */}
        <div
          className={`fixed left-0 lg:hidden top-0 bottom-0 bg-black bg-opacity-50 w-full h-full z-[9999]  transition-all duration-[400ms] ${
            gContext.visibleOffCanvas
              ? "opacity-1 visible"
              : "opacity-0 invisible"
          }`}
          onClick={() => {
            if (gContext.visibleOffCanvas) {
              gContext.closeOffCanvas()
            }
          }}
        ></div>
        <div
          className={`pb-[80px] md:pb-[110px] relative max-w-[1390px] mx-auto`}
        >
          <div className="">
            {/* <TopBar /> */}
            <Header />
            <main
              className="relative bg-white"
              style={{ boxShadow: `0px 0px 8px 3px rgb(0 0 0 / 10%)` }}
            >
              {children}
            </main>
            <Footer />
          </div>
        </div>
      </div>
      <ScrollToTop />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
