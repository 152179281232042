import React from "react"

import * as styles from "./Icons.module.css"

const ArrowRight = () => (
  <svg
    id="nc_icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className={styles.icon}
    data-type="outline"
  >
    <g transform="translate(0, 0)" fill="currentColor">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
    </g>
  </svg>
)

export default ArrowRight
