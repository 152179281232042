import React from "react"

import * as styles from "./Icons.module.css"

const Phone = () => (
  <svg
    id="nc_icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className={styles.icon}
    data-type="outline"
  >
    <g transform="translate(0, 0)" fill="none">
      <path
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M17,15l-3,3l-8-8l3-3L4,2 L1,5c0,9.941,8.059,18,18,18l3-3L17,15z"
        strokeLinejoin="miter"
      ></path>{" "}
      <path
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M14,1 c4.971,0,9,4.029,9,9"
        strokeLinejoin="miter"
      ></path>{" "}
      <path
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M14,5 c2.761,0,5,2.239,5,5"
        strokeLinejoin="miter"
      ></path>
    </g>
  </svg>
)

export default Phone
