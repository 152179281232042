import React, { useState, useContext, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import GlobalContext from "../../contexts/GlobalContext"
import { ArrowLeft, ArrowRight } from "../Icons"

const menuItems = [
  { title: "Homepage", link: "", isExternal: false, hasRooms: false },
  { title: "Rooms", link: "rooms", isExternal: false, hasRooms: true },
  // { title: "Blog", link: "blog", isExternal: false, hasRooms: false },
  { title: "Gallery", link: "gallery", isExternal: false, hasSubMenu: false },
  { title: "Contact", link: "contact", isExternal: false, hasRooms: false },
  {
    title: "Book Now",
    link: "booking",
    isExternal: false,
    hasRooms: false,
  },
]

const Sidebar = () => {
  const [subMenuVisible, setSubMenuVisible] = useState(false)
  const gContext = useContext(GlobalContext)
  const location = useLocation()

  const toggleSubMenu = () => {
    setSubMenuVisible(!subMenuVisible)
  }

  const handleLink = () => {
    gContext.toggleOffCanvas()
  }

  useEffect(() => {
    if (location.pathname.includes("/room/")) {
      toggleSubMenu()
    }
  }, [])

  const { allPrismicRoom } = useStaticQuery(graphql`
    query {
      allPrismicRoom(sort: { fields: data___top_menu_order, order: ASC }) {
        nodes {
          uid
          data {
            room_menu_title {
              text
            }
          }
        }
      }
    }
  `)

  const roomNodes = allPrismicRoom.nodes

  return (
    <>
      <nav
        className={`fixed z-[999999] bg-white lg:hidden w-[80%] max-w-[440px] h-screen overflow-x-hidden overflow-y-auto transform transition-all duration-[400ms] ${
          gContext.visibleOffCanvas ? "translate-x-0" : "translate-x-[-100%]"
        }`}
      >
        {/* Main menu */}
        <div
          className={`absolute inset-0 transform transition-all duration-[400ms] ${
            subMenuVisible ? "-translate-x-full" : "translate-x-0 z-10"
          }`}
        >
          <div>
            <div className="py-[12px] text-sm text-center uppercase text-menu font-heading font-bold tracking-[1px]">
              Menu
            </div>
          </div>
          <ul>
            {menuItems.map((item, index) => (
              <li
                key={index}
                className={`relative text-[13px] uppercase group border border-l-0 border-r-0  ${
                  index === 0 ? "" : "border-t-0"
                }`}
              >
                {item.isExternal ? (
                  <a
                    href={`${item.link}`}
                    className="block px-6 py-[15px] leading-5 transition text-menu "
                  >
                    {item.title}
                  </a>
                ) : (
                  <Link
                    to={`/${item.link}`}
                    className={`block px-6 py-[15px] leading-5 transition text-menu "
                    activeClassName="bg-black bg-opacity-10 ${
                      index + 1 === menuItems.length ? "text-sm" : ""
                    }`}
                    onClick={handleLink}
                  >
                    {item.title}
                  </Link>
                )}

                {item.hasRooms && (
                  <div
                    className="absolute top-0 right-0 flex items-center h-full pr-12 bg-white border border-t-0 border-b-0 border-r-0 cursor-pointer px-9 text-menu"
                    onClick={toggleSubMenu}
                  >
                    {roomNodes.length}
                    <div className="absolute top-0 bottom-0 z-10 flex items-center text-xs right-5 text-menu">
                      <ArrowRight />
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Sub menu */}
        <div
          className={`absolute inset-0 transform transition-all duration-[400ms] ${
            subMenuVisible ? "translate-x-0 z-10" : "translate-x-full"
          }`}
        >
          <div onClick={toggleSubMenu} className="relative cursor-pointer">
            <div className="py-[12px] text-sm text-center uppercase text-menu font-heading font-bold tracking-[1px]">
              Rooms
            </div>
            <div className="absolute top-0 bottom-0 z-10 flex items-center text-xs left-5 text-menu">
              <ArrowLeft />
            </div>
          </div>
          <ul>
            {roomNodes.map(({ uid, data }, index) => (
              <li
                key={index}
                className={`relative text-[13px] uppercase group border border-l-0 border-r-0  ${
                  index === 0 ? "" : "border-t-0"
                }`}
              >
                <Link
                  to={`/room/${uid}`}
                  className="block px-6 py-[15px] leading-5 transition text-menu "
                  activeClassName="bg-black bg-opacity-10"
                  onClick={handleLink}
                >
                  {data.room_menu_title.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Sidebar
