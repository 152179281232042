import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Container from "../Container/Container"
import EmailForm from "./EmailForm"

const menuItems = [
  { title: "Rooms", link: "rooms", isExternal: false },
  // { title: "Blog", link: "blog", isExternal: false },
  { title: "Gallery", link: "gallery", isExternal: false },
  // { title: "Video gallery", link: "video-gallery", isExternal: false },
  {
    title: "Hotel Policies",
    link: "hotel-policies",
    isExternal: false,
  },
  {
    title: "Privacy Policies",
    link: "privacy-policy",
    isExternal: false,
  },
  {
    title: "Book Now",
    link: "booking",
    isExternal: false,
  },
]

const Footer = () => {
  const { prismicCommon, prismicContact } = useStaticQuery(graphql`
    query {
      prismicCommon {
        data {
          footer_brand_title {
            text
          }
        }
      }
      prismicContact {
        data {
          contact_address {
            html
          }
          contact_email {
            text
          }
          contact_phone {
            text
          }
        }
      }
    }
  `)

  const commonData = prismicCommon.data
  const contactData = prismicContact.data

  return (
    <footer className="bg-[#ebf6fa] overflow-hidden py-[70px] md:py-[100px] ">
      <Container className="!py-0 ">
        <div className="flex flex-wrap ">
          <div className="w-full md:w-1/2 lg:w-[28%]">
            <div className="md:max-w-[270px]">
              <div className="pb-4 text-2xl leading-9 font-body text-menu">
                {commonData.footer_brand_title.text}
              </div>
              <div>
                <div
                  className="text-heading font-heading text-[15px] leading-9 tracking-[-0.3px]"
                  dangerouslySetInnerHTML={{
                    __html: contactData.contact_address.html,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="w-full pt-9 md:w-1/2 lg:w-[20%] lg:pl-6 md:pt-0">
            <nav>
              <ul>
                {menuItems.map((item, index) => (
                  <li
                    key={index}
                    className="text-[13px] tracking-[1px] relative group"
                  >
                    {item.isExternal ? (
                      <a
                        href={`${item.link}`}
                        className="block leading-9 uppercase text-menu"
                      >
                        {item.title}
                      </a>
                    ) : (
                      <Link
                        to={`/${item.link}`}
                        className="block leading-9 uppercase text-menu"
                      >
                        {item.title}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="w-full pt-9 md:w-1/2 lg:w-[20%] lg:pt-0 lg:pl-3 text-menu">
            <h3 className="font-body text-[23px] leading-10 mb-2">
              Contact Info
            </h3>
            <div className="text-[15px] text-heading leading-9 tracking-[0.5px]">
              <div>
                T:{" "}
                <a href={`tel:${contactData.contact_phone.text}`}>
                  {contactData.contact_phone.text}
                </a>
              </div>
              {/* <div>
                E:{" "}
                <a
                  href={`mailto:${contactData.contact_email.text}`}
                  className="underline"
                >
                  {contactData.contact_email.text}
                </a>
              </div> */}
            </div>
          </div>
          <div className="w-full pt-9 md:w-1/2 lg:w-[32%] lg:pt-0 lg:pl-3 text-menu">
            <h3 className="font-body text-[23px] leading-10 mb-2">
              Newsletter
            </h3>
            <div>
              <EmailForm />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
