import React from "react"

import * as styles from "./Icons.module.css"

const Dots = () => (
  <svg
    id="nc_icon"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className={styles.icon}
    data-type="outline"
    data-name="soup"
  >
    <g fill="currentColor">
      <circle fill="currentColor" cx="12" cy="12" r="2"></circle>{" "}
      <circle fill="currentColor" cx="3" cy="12" r="2"></circle>{" "}
      <circle fill="currentColor" cx="21" cy="12" r="2"></circle>
    </g>
  </svg>
)

export default Dots
