import React from "react"

import * as styles from "./Icons.module.css"

const Envelop = () => (
  <svg
    id="nc_icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className={styles.icon}
    data-type="outline"
  >
    <g transform="translate(0, 0)" fill="none">
      <polyline
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        points=" 19,7 12,14 5,7 "
        strokeLinejoin="miter"
      ></polyline>{" "}
      <rect
        x="1"
        y="3"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        width="22"
        height="18"
        strokeLinejoin="miter"
      ></rect>{" "}
      <line
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        x1="7"
        y1="15"
        x2="5"
        y2="17"
        strokeLinejoin="miter"
      ></line>{" "}
      <line
        data-color="color-2"
        fill="none"
        stroke="currentColor"
        vectorEffect="non-scaling-stroke"
        strokeLinecap="square"
        strokeMiterlimit="10"
        x1="17"
        y1="15"
        x2="19"
        y2="17"
        strokeLinejoin="miter"
      ></line>{" "}
    </g>
  </svg>
)

export default Envelop
