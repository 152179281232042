import React, { useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "../Logo/Logo"
import GlobalContext from "../../contexts/GlobalContext"
import { MenuBar } from "../Icons"

const menuItems = [
  { title: "Homepage", link: "", isExternal: false, hasSubMenu: false },
  { title: "Rooms", link: "rooms", isExternal: false, hasSubMenu: true },
  // { title: "Blog", link: "blog", isExternal: false, hasSubMenu: false },
  { title: "Gallery", link: "gallery", isExternal: false, hasSubMenu: false },
  { title: "Contact", link: "contact", isExternal: false, hasSubMenu: false },
  {
    title: "Book Now",
    link: "booking",
    isExternal: false,
    hasSubMenu: false,
  },
]

const Header = () => {
  const { allPrismicRoom } = useStaticQuery(graphql`
    query {
      allPrismicRoom(sort: { fields: data___top_menu_order, order: ASC }) {
        nodes {
          uid
          data {
            room_menu_title {
              text
            }
          }
        }
      }
    }
  `)

  const roomNodes = allPrismicRoom.nodes

  const gContext = useContext(GlobalContext)

  return (
    <header className="sticky top-0 z-50">
      <div
        className="flex flex-col items-center justify-center py-[35px] relative z-50 bg-white"
        style={{ boxShadow: `0px 0px 8px 3px rgb(0 0 0 / 10%)` }}
      >
        <div className="pb-[28px]">
          <Logo />
        </div>
        <div
          className="px-5 py-3 cursor-pointer lg:hidden"
          onClick={gContext.toggleOffCanvas}
        >
          <div className="flex items-center text-2xl text-menu">
            <MenuBar />
          </div>
        </div>
        <nav className="hidden lg:block">
          <ul className="flex">
            {menuItems.map((item, index) => (
              <li key={index} className="text-xs tracking-[3px] relative group">
                {item.isExternal ? (
                  <a
                    href={`${item.link}`}
                    className="block px-10 leading-5 text-center uppercase transition text-menu hover:text-primary"
                  >
                    {item.title}
                  </a>
                ) : (
                  <Link
                    to={`/${item.link}`}
                    className={`block px-10 leading-5 text-center uppercase transition text-menu hover:text-primary ${
                      index + 1 === menuItems.length ? "text-sm" : ""
                    }`}
                    activeClassName="text-primary"
                  >
                    {item.title}
                  </Link>
                )}

                {item.hasSubMenu && (
                  <ul className="absolute pt-1 top-4 w-[280px] left-10 z-50 hidden group-hover:block">
                    {roomNodes.map(({ uid, data }) => (
                      <li
                        key={uid}
                        className="  first:pt-[13px] last:pb-[13px] bg-[rgba(255,255,255,0.94)]"
                      >
                        {
                          <Link
                            to={`/room/${uid}`}
                            className="block py-[10px] px-[33px] leading-[14.4px] hover:bg-[rgba(179,179,179,0.94)]   text-menu  hover:text-white transition"
                            activeClassName="bg-[rgba(179,179,179,0.94)] text-white"
                          >
                            {data.room_menu_title.text}
                          </Link>
                        }
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
