import React from "react"

const Container = ({ children, className = "" }) => (
  <>
    <div
      className={`px-6 py-10 sm:px-10 sm:py-12  md:py-14 md:px-12  lg:max-w-[1170px] mx-auto ${className}`}
    >
      {children}
    </div>
  </>
)

export default Container
