import React, { useState } from "react"
import { LocationProvider } from "@reach/router"
const GlobalContext = React.createContext()

const GlobalProvider = ({ children }) => {
  const [themeDark, setThemeDark] = useState(false)
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false)
  const [visibleTopBar, setVisibleTopBar] = useState(true)

  const toggleTheme = () => {
    setThemeDark(!themeDark)
  }

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas)
  }
  const closeOffCanvas = () => {
    setVisibleOffCanvas(false)
  }

  const toggleTopBar = () => {
    setVisibleTopBar(!visibleTopBar)
  }

  return (
    <LocationProvider>
      <GlobalContext.Provider
        value={{
          themeDark,
          toggleTheme,
          visibleOffCanvas,
          toggleOffCanvas,
          closeOffCanvas,
          visibleTopBar,
          toggleTopBar,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </LocationProvider>
  )
}

export default GlobalContext
export { GlobalProvider }
