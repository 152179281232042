import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Logo = () => {
  const { prismicCommon } = useStaticQuery(graphql`
    query {
      prismicCommon {
        data {
          brand_title {
            text
          }
          brand_intro {
            text
          }
        }
      }
    }
  `)

  const commonData = prismicCommon.data

  return (
    <>
      <Link
        to="/"
        className="inline-flex flex-col justify-center text-center font-display"
      >
        <strong className="text-[20px] text-primary font-normal tracking-[1px] leading-[30px]">
          {commonData.brand_title.text}
        </strong>
        <span className="text-[10px] tracking-[1px] leading-[10px] mt-[6px] text-[#aeaab1]">
          {commonData.brand_intro.text}
        </span>
      </Link>
    </>
  )
}

export default Logo
