import React, { useEffect, useState } from "react"

import { ArrowLeft } from "../Icons"

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  // Top: 0 takes us all the way back to the top of the page
  // Behavior: smooth keeps it smooth!
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 250) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)

    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <>
      {isVisible && (
        <div
          className="fixed flex items-center justify-center w-12 h-12 border rounded-full cursor-pointer bottom-8 right-8 border-primary"
          onClick={scrollToTop}
        >
          <span className="flex items-center justify-center text-sm transform rotate-90 text-primary">
            <ArrowLeft />
          </span>
        </div>
      )}
    </>
  )
}

export default ScrollToTop
