/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { GlobalProvider } from "./src/contexts/GlobalContext"
import Layout from "./src/components/Layout/Layout"

import "keen-slider/keen-slider.min.css"

import "./src/styles/fonts.css"
import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
)
