import React from "react"

import * as styles from "./Icons.module.css"

const ArrowLeft = () => (
  <svg
    id="nc_icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    className={styles.icon}
    data-type="outline"
  >
    <g transform="translate(0, 0)" fill="currentColor">
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"></path>
    </g>
  </svg>
)

export default ArrowLeft
